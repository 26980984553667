<template lang="pug">
  .wrapper.i-wrap.cms-article-wrap
    .section-left

    .section-mid
      div(v-for="issue in issues")
        p {{issue.user.displayName}}
        p {{issue.type}}
        p {{issue.typeId}}
        p {{issue.subject}}
        p {{issue.content}}
        //- p {{issue.content}}
    .section-right
      

</template>

<script>
// @ is an alias to /src
import LeftBar from '@/components/LeftBar'
import RightBar from '@/components/RightBar'
export default {
  name: 'CmsAdminIssues',
  props:{
    id:String,
  },
  components: {

  },
  data(){
    return{
      issues:[],
      
    }
  },
  metaInfo: {
    title: 'Admin- 臭豆腐電影: 電影∣影集∣評分∣評論',
    // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
  },
  methods:{
    async init(){
      var {data} = await this.axios.get(`/cms/issues`)
      this.issues = data
    },
    
  },
  async created(){
    this.init()
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss">

</style>
